const state = {
    currentNewsItemObject:JSON.parse(sessionStorage.getItem('currentGameNewsItemObject'))||{}
 }
 const mutations = {
    //设置菜单当前索引
    setCurrentNewsItemObject(state,payload){
        state.currentNewsItemObject = payload;
        sessionStorage.setItem('currentGameNewsItemObject',JSON.stringify(payload));
    },
    removeCurrentNewsItemObject(state,payload){
        state.currentNewsItemObject = {};
        sessionStorage.removeItem('currentGameNewsItemObject');
    }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
 }