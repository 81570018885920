import Home from '../../views/Home.vue'
import buyListRoute from '../buyList';
import chatRoute from '../chat'

const baseRoutes = [
    {
        path: '/',
        component:() => import(/* webpackChunkName: "home" */ '../../views/Home.vue'),
        children:[
            {
                path:"",
                component:() => import(/* webpackChunkName: "content" */ '../../views/home/content.vue'),
                meta:{menuIndex:0},
            },
            {
                path:"/publishProduct",
                component:() => import(/* webpackChunkName: "publishProduct" */ '../../views/publishProduct/index.vue'),
                meta:{menuIndex:7}
            },
            {
                
                path:"/buyList",
                component:() => import(/* webpackChunkName: "buyList" */ '../../views/buyList/index.vue'),
                meta:{menuIndex:1},
                props:({params})=>{
                    return {gameItem:params.gameItem}
                },
                children:[
                    {
                        name:"woyaomai",
                        path:"",
                        component:() => import(/* webpackChunkName: "buyList" */ '../../views/buyList/components/games-select-list.vue'),
                        meta:{menuIndex:1},
                    },
                    {
                        name:"productList",
                        path:"productList",
                        component:() => import(/* webpackChunkName: "productList" */ '../../views/buyList/components/games-filter-list.vue'),
                        meta:{menuIndex:1},
                        props:({params})=>{
                            return {currentSelectGameItem:params.currentSelectGameItem}
                        }
                    },
                    {
                        name:"accountProductDetails",
                        path:"accountProductDetails",
                        component:() => import(/* webpackChunkName: "accountProductDetails" */ '../../views/buyList/components/games-account-detail.vue'),
                        meta:{menuIndex:1},
                        props:({params})=>{
                            return {currentProduct:params.currentProduct}
                        }
                    }
                ]
            },
            {
                path:"/guarantee",
                component:() => import(/* webpackChunkName: "guarantee" */ '../../views/guarantee/index.vue'),
                meta:{menuIndex:2},
            },
            {
                path:"/businessIntroduction",
                component:() => import(/* webpackChunkName: "businessIntroduction" */ '../../views/businessIntroduction/index.vue'),
                meta:{menuIndex:3},
            },
            {
                path:"/helpCenter",
                component:() => import(/* webpackChunkName: "helpCenter" */ '../../views/helpCenter/index.vue'),
                meta:{menuIndex:4},
            },
            {
                path:"/gamesNews",
                component:() => import(/* webpackChunkName: "gamesNews" */ '../../views/gamesNews/index.vue'),
                meta:{menuIndex:5},
                children:[
                    {
                        path:"",
                        component:() => import(/* webpackChunkName: "gamesNews" */ '../../views/gamesNews/components/news-list-content.vue'),
                        meta:{menuIndex:5},
                    },
                    {
                        name:"newsDetails",
                        path:"newsDetails",
                        component:() => import(/* webpackChunkName: "newsDetails" */ '../../views/gamesNews/components/news-details-content.vue'),
                        meta:{menuIndex:5},
                        props:(route)=>{
                            return {articleObject:route.params.articleObject}
                        }
                    },
                ]
            },
            {
                path:"/myCenter",
                component:() => import(/* webpackChunkName: "myCenter" */ '../../views/myCenter/index.vue'),
                meta:{menuIndex:7},
                children:[
                    {
                        path:"myProduct",
                        component:() => import(/* webpackChunkName: "myProduct" */ '../../views/myCenter/components/my-product.vue'),
                        meta:{menuIndex:7},
                    },
                    {
                        path:"myOrder",
                        component:() => import(/* webpackChunkName: "myOrder" */ '../../views/myCenter/components/my-order.vue'),
                        meta:{menuIndex:7},
                    },
                    {
                        path:"myCollect",
                        component:() => import(/* webpackChunkName: "myCollect" */ '../../views/myCenter/components/my-collect.vue'),
                        meta:{menuIndex:7},
                    },
                    {
                        path:"myAccountSetting",
                        component:() => import(/* webpackChunkName: "myAccountSetting" */ '../../views/myCenter/components/my-account-setting.vue'),
                        meta:{menuIndex:7},
                        children:[
                            {
                                path:"myProfile",
                                component:() => import(/* webpackChunkName: "myProfile" */ '../../views/myCenter/components/my-account-setting-profile.vue'),
                                meta:{menuIndex:7},
                            },
                            {
                                path:"realNameAuthentication",
                                component:() => import(/* webpackChunkName: "realNameAuthentication" */ '../../views/myCenter/components/my-account-setting-authentication.vue'),
                                meta:{menuIndex:7},
                            },
                            {
                                path:"passwordModify",
                                component:() => import(/* webpackChunkName: "passwordModify" */ '../../views/myCenter/components/my-account-setting-password-modify.vue'),
                                meta:{menuIndex:7},
                            },
                        ]
                    },
                    { 
                        path:"myWithdraw",
                        component:() => import(/* webpackChunkName: "myWithdraw" */ '../../views/myCenter/components/my-withdraw.vue'),
                        meta:{menuIndex:7},
                    }
                ]
            },
            {
                path:"/downloadApp", 
                component:() => import(/* webpackChunkName: "downloadApp" */ '../../views/downloadApp/index.vue'),
                meta:{menuIndex:8},
            }
        ]
        
    },
    { 
        path:'/login',
        component:() => import(/* webpackChunkName: "login" */ '../../views/login/index.vue'),
        meta:{menuIndex:6},
    },
    {
        path:'/register',
        component:() => import(/* webpackChunkName: "register" */ '@/views/register/index.vue'),
        meta:{menuIndex:6},
    },
    {

        path:'/error',
        component:() => import(/* webpackChunkName: "404" */ '@/views/error/error.vue'),
        meta:{menuIndex:6},
    },
    chatRoute

]

export default baseRoutes;