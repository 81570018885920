import request from "../../utils/request";

//用户登录
export const login = (data)=>{
    return  request({
        method:'POST',
        url:'/user/login',
        data
    })
}

//用户注册
export const register = (data)=>{ 
   return  request({
        method:'POST',
        url:'/user/register',
        data
    })
}

// export const checkUsernameExist = (account)=>{
//     return  request({
//          method:'POST',
//          url:`/user/checkUsernameExist/${account}`,
//      })
//  }

 //发送验证码
 export const sendVerifyCode = (codeType,phoneNumber)=>{
    return  request({
         method:'POST',
         url:`/user/sendVerifyCode/${codeType}/${phoneNumber}`,
     })
 }

 //用户登录
export const getUserProfile = (data)=>{
    return  request({
        method:'GET',
        url:'/user/getUserProfile',
        data
    })
}

 //更新用户基本资料
 export const updateWebUserBaseProfile = (data)=>{
    return  request({
        method:'POST',
        url:'/user/updateWebUserBaseProfile',
        data
    })
}

 //用户退出登录
 export const userLogout = (data)=>{
    return  request({
        method:'post',
        url:'/user/userLogout',
        data
    })
}
 
//查询客服QQ、微信是否存在
export const queryCustomerServiceInfo = (data)=>{
return  request({
    method:'POST',
    url:'/user/queryCustomerServiceInfo',
    data
})
}
