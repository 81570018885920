import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './common/index'
import store from '../store'
import app from '@/main.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode:'hash',
  routes,
  linkActiveClass: 'active', //菜单被激活时的样式
})

export default router;

router.beforeEach(async (to, from, next) => {
  //保存上一个路由,方便商品列表展示的数据是重新加载还是从sessionStory获取。如果是/buyList跳转过去的就重新加载
  Vue.prototype.$previousRoute = from;
  // 在路由切换前保存当前滚动位置
  if(to.path!=='/myCenter/myAccountSetting/myProfile'){
    const currentPosition = window.scrollY || document.documentElement.scrollTop;
    sessionStorage.setItem('scrollPosition', currentPosition);
  }
  // next();
  
  const whiteList = ['/','/register','/login','/error','/buyList','/guarantee','/businessIntroduction','/helpCenter','/gamesNews','/downloadApp'];
  //console.log(store.state.user.userToken)
  if(store.state.user.userToken){
    //token存在
    if(to.path==='/login'){
      // console.log('laile')
      next('/')
      return;
    }
    if(to.path.startsWith('/myCenter')){
      try{
        const code = await store.dispatch('user/loadUserProfile');
        if(code===200){ //说明token有效，并且正确返回数据
          next()
        }else{
          next('/login')
        }
      }catch(err){
        next('/login')
      }
    }
    next();
  }else{
    //token不存在
    if(whiteList.indexOf(to.path)>-1||to.path.startsWith('/buyList')||to.path.startsWith('/gamesNews')){
      next()
    }else{
      if(to.path==='/publishProduct'&&from.path==='/'){
        app.$message.warning('您好，请先登录！')
        return;
      }
      next('/')
    }
  }
});
 
router.afterEach((to,from) => {
  //设置菜单的位置
  store.commit('home/setCurrentGlobalMenuIndex',to.meta.menuIndex);
  // 在路由切换后恢复滚动位置
  window.scrollTo(0, sessionStorage.getItem('scrollPosition') || 0);
});

