const state = {
   gameIconList:[],
   sliderLeft:47+'px;',
   currentGlobalMenuIndex:0,
   homeSettings:JSON.parse(localStorage.getItem('homeSettings'))||{}
}
const mutations = { 

   setGameIconList(state,data){
      state.gameIconList=data;
   },

   updateSliderLeft(state,data){
      //console.log('我是state中的sliderLeft'+JSON.stringify(data))
      state.sliderLeft=data
   },
   setCurrentGlobalMenuIndex(state,data){
      //console.log('当前全局菜单索引是：'+data)
      state.currentGlobalMenuIndex = data;
   },
   setHomeSettings(state,data){
      state.homeSettings = data;
      localStorage.setItem('homeSettings',JSON.stringify(data));
   }

}

const actions = {
   
}

const getters = {

}

export default {
   namespaced:true,
    state,
    mutations,
    actions,
    getters
}