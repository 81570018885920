const state = {
    tempSelectFilterConditional:{},
    isShowMask:false,
    filterResultForGamesList:JSON.parse(sessionStorage.getItem('currentFilterResultForGamesList'))||{}, //当前筛选后的游戏商品列表 
    currentSelectGameItem:JSON.parse(sessionStorage.getItem('currentSelectGameItem'))||{}, //当前选择的游戏
    currentShowGameDetailItem:JSON.parse(sessionStorage.getItem('currentShowGameDetailItem'))||{}, //当前选择的具体游戏商品
   //  totalCount:0
 }
 const mutations = {
    //“我要买”菜单中的筛选的游戏列表
    setTempSelectFilterConditional(state,payload){
       state.tempSelectFilterConditional[payload.itemName]=payload.itemValue;
       //console.log('setTempSelectFilterConditional==='+JSON.stringify(state.tempSelectFilterConditional))
    },
    //当前选择的游戏
    setCurrentSelectGameItem(state,payload){
      state.currentSelectGameItem = payload;
      sessionStorage.setItem('currentSelectGameItem',JSON.stringify(payload))
    },
    //根据筛选条件，获取的游戏列表
    setFilterResultForGamesList(state,payload){
      //console.log('payload=='+JSON.stringify(payload))
      state.filterResultForGamesList = payload;
      sessionStorage.setItem('currentFilterResultForGamesList',JSON.stringify(payload))
    }, 
    //当前选择的具体游戏商品
    setCurrentShowGameDetailItem(state,payload){
      state.currentShowGameDetailItem = payload;
      sessionStorage.setItem('currentShowGameDetailItem',JSON.stringify(payload))
    },
    //重置单选、多选、输入框组件的记录
    resetTempSelectFilterConditional(state,payload){
      payload.forEach(element => {
         delete state.tempSelectFilterConditional[element.aliasTitle];
      });
      //console.log('resetTempSelectGamesList==='+JSON.stringify(state.tempSelectGamesList))
    },
    setIsShowMask(state,payload){
      // console.log('payload=='+payload)
      state.isShowMask = payload;
    },
    //重置所有选择项
    resetAllSelectedItems(state,payload){
      state.tempSelectFilterConditional = {pageNumber:1,pageSize:20};
      //console.log('setTempSelectGamesList==='+JSON.stringify(state.tempSelectGamesList));
    },
    //更新收藏商品状态
    setProductionCollectionStatus(state,payload){
      const productItem = state.filterResultForGamesList.find(item.id===payload.id);
      if(productItem){
        productItem.asCollect = payload.asCollect;
      }
    }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
     state,
     mutations,
     actions,
     getters
 }