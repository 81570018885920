<template>
  <div class="new-header-containner">
    <!-- 左边 :style="{width:menuTitleMinWidth(index)}"--> 
    <div class="new-header-content-left">
        <img alt="logo" class="new-header-content-logo" src="/images/home/home_logo_icon.png" >
        <ul> 
          <li v-for="(item,index) in menuData" :key="index" @click="clickMenuHandler(index)" >
            <p class="new-header-content-menu-title" :style="{color:currentGlobalMenuIndex===index&&currentGlobalMenuIndex<6?'#F02233':''}">{{item}}</p>
          </li>
          <div class="new-header-content-menu-slider" :style="{width:menuSliderWidth,left:menuSliderLeft}"></div>
        </ul>
    </div>
    <!-- 右边 --> 
    <div class="new-header-content-right">
      <!-- 搜索框 -->
      <div class="new-header-content-search-box">
        <img class="new-header-content-search-icon" src="/images/home/home_search_icon.png" alt="searchIcon" >
        <input class="new-header-content-search-input" type="text" maxlength="24px" placeholder="输入游戏名称搜索" />
        <div class="new-header-content-search-title">
          <p class="new-header-content-search-title-text">搜索</p>
        </div>
      </div>
      <!-- 下载app -->
      <div class="new-header-content-download-app-btn" @click="clickDownloadAppHandler()">
        <img class="new-header-content-download-app-btn-phone-icon" src="/images/home/home_phone_icon.png" alt="downloadApp">
        <p class="new-header-content-download-app-btn-text">下载App</p>
      </div>
      <!-- 登录或注册 -->
      <el-dropdown v-if="isShowAvatar" placement="bottom-start" @command="clickSubMenuHnadler">
      <div class="new-header-content-has-login-box">
        <img class="new-header-content-has-login-avatar" :src="userProfile&&userProfile.avatar?userProfile.avatar:'/images/home/home_login_avatar_man.png'" alt="avatar" >
      </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="myCenter">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else class="new-header-content-login-and-register-btn" @click="clickLoginOrRegisterBtnHandler()">
        <p class="new-header-content-login-and-register-btn-text">登录/注册</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
import { userLogout } from '@/services/user';
export default {
  name:"HomeNewHeader",
  data(){
    return{
      menuData:["首页","我要买","中介担保","业务介绍","帮助中心","游戏资讯"],
      currentMenuIndex:0,
    }
  },
  methods:{
    ...mapMutations('user',['clearUserProfile']),
    //点击菜单操作
    clickMenuHandler(index){
      this.currentMenuIndex = index;
      switch(index){
          case 0:
              this.$router.push('/',()=>{});
              break;
          case 1:
              this.$router.push('/buyList',()=>{});
              break;
          case 2:
              this.$router.push('/guarantee',()=>{});
              break;
          case 3:
              this.$router.push('/businessIntroduction',()=>{});
              break;
          case 4:
              this.$router.push('/helpCenter',()=>{});
              break;
          case 5:
              this.$router.push('/gamesNews',()=>{});
              break;
          default:
              break;
      }
    },
    //点击下载app按钮操作
    clickDownloadAppHandler(){
      this.$router.push('/downloadApp',()=>{});
    },
    //点击登录操作
    clickLoginOrRegisterBtnHandler(){
      this.$router.push("/login",()=>{});
    },
    //登录状态下，点击头像，选择子菜单行为
    async clickSubMenuHnadler(command){
      if(command==='myCenter'){ //进入个人中心
        this.$router.push({path:'/myCenter/myProduct'},()=>{
          this.$store.commit('user/setMyCenterMenuIndex',0);
        })
      }else if(command==='logout'){
        //清空缓存记录
        try{
          const {data} = await userLogout();
          if(data.code==200){
            this.$message.success('退出登录成功')
            localStorage.clear(); //清空localStorage缓存
            this.clearUserProfile(); //清空vuex
            this.$router.push('/')
          }
        }catch(err){
            localStorage.clear();  //清空localStorage缓存
            this.clearUserProfile(); //清空vuex
            this.$router.push('/')
        }
      }
    }
  },
  computed:{
    ...mapState({
      currentGlobalMenuIndex:state=>state.home.currentGlobalMenuIndex,
      userProfile:state=>state.user.userProfile
    }),
    isShowAvatar(){
      return this.userProfile&&this.userProfile.avatar&&this.userProfile.avatar.length>0;
    },
    menuSliderWidth(){
      return this.currentGlobalMenuIndex<6?this.menuData[this.currentGlobalMenuIndex].length*16+'px':'0px';
    },
    menuSliderLeft(){
      let tempLength = 0;
      this.menuData.forEach((item,index)=>{
        if(index<this.currentGlobalMenuIndex){
          tempLength += this.menuData[index].length*16;
        }
      });
      let ttempLength = this.currentGlobalMenuIndex===0?0:tempLength;
      return (this.currentGlobalMenuIndex+1)*40 +ttempLength+'px';
    }
  }
}
</script>

<style lang="less" scoped> 
.new-header-containner{
    width: calc(100vw);
    min-width: 1440px;
    height: 60px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .new-header-content-left{
        min-width: 730px;
        height: 60px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        .new-header-content-logo{
          margin-left: 30px;
          width: 123px;
          height: 40px;
        }
        ul{
          height: 60px;
          list-style-type: none;
          padding: 0px;
          display: flex;
          align-items: center;
          cursor: default;
          position: relative;
          li{
            list-style: none;
            height: 60px;
            margin-left: 40px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .new-header-content-menu-title{
              font-family: MicrosoftYaHei;
              font-size: 16px;
              color: #424242;
              line-height: 21px;
              text-align: right;
              font-style: normal;
            }
            &:hover .new-header-content-menu-title{
              color: #F02233;
            }
          }
          .new-header-content-menu-slider{
            position: absolute;
            left: 40px;
            bottom: 0px;
            width: 45px;
            height: 4px;
            background: #F02233;
            border-radius: 100px 100px 0px 0px;
            transition: all 0.3s ease-in-out;
          }
        }
    }
    .new-header-content-right{
      min-width: 710px;
      padding-right: 30px;
      height: 60px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .new-header-content-search-box{
        width: 280px;
        height: 36px;
        background: #F5F5F5;
        border-radius: 6px;
        border: 1px solid #EDEDED;
        display: flex;
        align-items: center;
        position: relative;
        .new-header-content-search-icon{
          margin-left: 10px;
          width: 15px;
          height: 16px;
        }
        .new-header-content-search-input{
          margin-left: 10px;
          width: 180px;
          height: 34px;
          outline: none;
          border: 0px;
          background: #F5F5F5;
          &::placeholder{
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #C3C3C3;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }
        .new-header-content-search-title{
          position: absolute;
          top: -1px;
          right: -1px;
          width: 56px;
          height: 36px;
          background: #F02233;
          border-radius: 0px 6px 6px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          .new-header-content-search-title-text{
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 19px;
            text-align: right;
            font-style: normal;
          }
        }
      }
      .new-header-content-download-app-btn{
        margin-left: 20px;
        width: 120px;
        height: 36px;
        background: #3A3633;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .new-header-content-download-app-btn-phone-icon{
          width: 14px;
          height: 20px;
        }
        .new-header-content-download-app-btn-text{
          margin-left: 10px;
          height: 19px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
      }
      .new-header-content-has-login-box{
        margin-left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 21px;
        cursor: pointer;
        .new-header-content-has-login-avatar{
          width: 42px;
          height: 42px;
          border-radius: 21px;
        }
      }
      .new-header-content-login-and-register-btn{
        margin-left: 20px;
        width: 120px;
        height: 36px;
        background: #F02233;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .new-header-content-login-and-register-btn-text{
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 19px;
          text-align: right;
          font-style: normal;
        }
      }
    }
}
</style>